
import request from '../utils/axios/index'

export const BASE_URL = '/websites-server/rest/info/information'
export function getInformationDetail (data) {
  return request({
    method: 'post',
    url: `${BASE_URL}/getDetail`,
    data
  })
}
