<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-img`">
      <img src="../../assets/image/press/bg_view.png">
    </div>
    <caption :class="`${prefixCls}-header`">
      <h2 :class="`${prefixCls}-header-title`">{{pressDetail.title}}</h2>
      <div :class="`${prefixCls}-header-info`">
        <span>发布时间：{{pressDetail.publishDate}}</span>
        <!-- <span>|</span>
        <span>
          浏览次数：{{Math.floor(Math.random()*1000)}}
        </span> -->
      </div>
    </caption>
    <main :class="`${prefixCls}-content`">
      <!-- <div :class="`${prefixCls}-content-img`" align="center">
        <img :src="pressDetail.picturePath" width="100%" height="100%" vspace="20">
      </div> -->
      <section v-html="pressDetail.infoContent">
      </section>
    </main>
  </div>
</template>
<script>
import { getInformationDetail } from '@/api/detail'
export default {
  data () {
    return {
      prefixCls: this.$prefix + '-views-detail-press',
      pressDetail: {}
    }
  },
  props: {
    id: {
      type: String
    }
  },
  created () {
    getInformationDetail({ id: this.id }).then(res => {
      this.pressDetail = res.data.data
    })
  },
  computed: {

  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -views-detail-press;
  .#{$prefixCls}{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    &-img{
      width: 100%;
      height: 580px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &-header {
      display: flex;
      flex-direction: column;
      margin: 50px 0;
      position: relative;
      width: 100%;
      &-info{
        display: flex;
        flex-direction: row;
        justify-content: center;
        span {
          margin-right: 20px;
          font-weight: 600;
        }
      }
      &-back{
        position: absolute;
        left: 200px;
        top: 50px;
        font-size: 30px;
        cursor: pointer;
        transition: all .5s;
        width: 50px;
        border-radius: 20px;
        &:hover{
          background: #3ea0e7;
          color: #fff;
        }
      }
    }
    &-content{
      width: $content-width;
      height: 80%;
      margin-bottom: 100px;
      text-align: left;
      &-img{
        width: 40%;
        min-width: 300px;
        min-height: 210px;
        max-height: 400px;
        padding: 10px;
        float: left;
        clear: both;
      }
    }
  }
</style>
